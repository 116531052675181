import React, { createRef, useState } from "react";
import * as yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";
import { Field, Form } from "react-final-form";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Layout from "../../layouts";
import Seo from "../../components/Seo";
import Banner from "../../images/services/ux/content-img.jpg";
import Banner02 from "../../images/banner3.jpg";

import { sendEmail } from "../../services/contact";

// Testimonial
import TabcmpSet from "../../components/tab/TabcmpSet";
import ClientTestimonial1 from "../../components/ClientTestimonialRobbiePossibl";
import ClientTestimonial3 from "../../components/ClientTestimonialGaryPokitpal";
import ClientTestimonial2 from "../../components/ClientTestimonialErajLilardia";
import ClientTestimonial4 from "../../components/ClientTestimonialMustafaDataGlider";
import ClientTestimonial5 from "../../components/ClientTestimonialBenOxygen";
import GaryCobain from "../../images/Clients/GaryCobain.jpeg";
import ErajRatnayake from "../../images/Clients/ErajRatnayake.jpg";
import RobbieBrender from "../../images/Clients/RobbieBrender.jpg";
import BenTaylor from "../../images/Clients/BenTaylor.jpg";
import MustafaBensan from "../../images/Clients/MustafaBensan.jpg";

// Contact
import SvgIconsSet from "../../components/SvgIconsSet";

const UX = () => {
  const [capture, setCaptureToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const recaptchaRef = createRef();

  const tabData = [
    {
      label: "Ben Taylor",
      text: "Oxygen",
      image: BenTaylor,
      content: <ClientTestimonial5 />,
    },
    {
      label: "Mustafa Bensan",
      text: "Data Glider",
      image: MustafaBensan,
      content: <ClientTestimonial4 />,
    },
    {
      label: "Eraj Ratnayake",
      text: "Lilardia Capital",
      image: ErajRatnayake,
      content: <ClientTestimonial2 />,
    },
    {
      label: "Robbie Brender",
      text: "Possibl",
      image: RobbieBrender,
      content: <ClientTestimonial1 />,
    },
    {
      label: "Gary Cobain",
      text: "PokitPal",
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
  ];

  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Name can only contain Latin letters.",
      )
      .required("Name is a required field"),
    email: yup.string().email().required("Email is a required field"),
    Phone: yup.number(),
  });

  const validate = (values) => {
    const errors = {
      inner: [],
    };
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values, form) => {
    if (capture === null) {
      setRecaptchaError(true);
      return;
    }
    setRecaptchaError(false);

    const formData = { ...values };
    formData.FormType = "ResourcePromotion";

    try {
      setSubmitting(true);
      const response = await sendEmail(formData);

      recaptchaRef?.current?.reset();

      if (response) {
        form.restart();
        setFormSuccess(true);
        setTimeout(() => setFormSuccess(false), 10000);
      }
      setFormSuccess(false);
      setTimeout(() => setFormSuccess(false), 10000);
    } catch (error) {
      console.log(error);
      setSubmissionError("Error occurred in submitting !");
      setTimeout(() => setSubmissionError(null), 10000);
    } finally {
      setSubmitting(false);
    }
  };

  const onReCAPTCHAChange = (token) => {
    setCaptureToken(token);
  };

  return (
    <Layout bodyClass="marketing-campaign-page service-page">
      <Seo
        title="Resources Promotion"
        description="iTelaSoft is an Australian-owned company providing IT services andsoftware product development. We specialise in strategy help entrepreneurs transform ground breaking ideas into successful solutions."
      />

      <div className="campaign-banner" id="up">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h6 className="align-center-xs">
                Unlock the Full Potential of Your Business with Our Expert
                Talents
              </h6>
              <h4 className="title-1 mb-2 align-center-xs">
                Empowering Your Vision with
                {" "}
                <br />
                Cutting-Edge
                {" "}
                <span className="">Skills and Expertise</span>
              </h4>
              <ul className="mb-2">
                <li>
                  14 days
                  {" "}
                  <span className="font-weight-bold">Free Trial</span>
                </li>
                <li>
                  Within
                  {" "}
                  <span className="font-weight-bold">48hrs</span>
                  {" "}
                  onboarding
                </li>
                <li>
                  Greater overlapping
                  {" "}
                  <span className="font-weight-bold">time zone</span>
                </li>
                <li>
                  <span className="font-weight-bold">Guaranteed </span>
                  {" "}
                  replacement
                </li>
              </ul>
              <p className="align-center-xs">
                Our talented team specialises in delivering top-tier software
                solutions that drive innovation and growth. Let&apos;s
                collaborate to achieve your business goals.
              </p>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center mt-1 mt-sm-0">
              <Row className="statistics">
                <Col lg={6} md={6} sm={6} className="stat-box">
                  <h2 className="stat-valu">15+</h2>
                  <h6 className="stat-des">Years of Excellence</h6>
                </Col>
                <Col lg={6} md={6} sm={6} className="stat-box">
                  <h2 className="stat-valu">4</h2>
                  <h6 className="stat-des">International Offices</h6>
                </Col>
                <Col lg={6} md={6} sm={6} className="stat-box">
                  <h2 className="stat-valu">7</h2>
                  <h6 className="stat-des">Leading Tech Partnerships</h6>
                </Col>
                <Col lg={6} md={6} sm={6} className="stat-box">
                  <h2 className="stat-valu">150+</h2>
                  <h6 className="stat-des">Workforce</h6>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="banner-label py-1">
        <Container>
          <div>
            <Row className="">
              <Col className="label-text">
                Australia’s Most Reliable Outsourcing Partner
              </Col>
              <Col className="label-text">Exclusive Limited-Time Offer!</Col>
            </Row>
          </div>
        </Container>
      </div>

      <section className="limited-offer">
        <Container>
          <div>
            <div className="title title-1 mb-2 align-center-xs">
              Introducing Our
              {" "}
              <span className="text-yellow">Limited Time Offer! </span>
            </div>
            <p className="align-center-xs">
              To celebrate our decade of success and to support your growing
              business needs, we&apos;re excited to announce
              <br />
              a special limited-time offer: Enjoy these heavily
              {" "}
              <span className="text-yellow font-weight-bold">
                discounted resource rates for three months!
              </span>
            </p>
          </div>
          <div className="getting-touch-right">
            <a
              className="btn btn-primary btn-icon-button mt-1"
              href="/contact/"
            >
              <span className="btn-text">Take Advantage Today!</span>
            </a>
          </div>
        </Container>
      </section>

      <section className="pricing-plan">
        <Container className="py-2">
          <div className="">
            <h2 className="title-1 mb-2 align-center-xs">
              Consider
              {" "}
              <span className="">a suitable pricing plan</span>
              {" "}
              for
              your specific need
            </h2>
            <p className="align-center-xs">
              Discover flexible and customised pricing plans that suit your
              unique needs at iTelaSoft. Whether you&apos;re a startup or an
              enterprise, our options deliver maximum value without breaking the
              bank. Let&apos;s grow together!
            </p>
            <div className="mt-3">
              <Row>
                <Col md={6} lg={6} className="mb-2">
                  <Card className="plan-card">
                    <CardBody>
                      <h2 className="font-weight-bold">AUD 20/hour</h2>
                      <CardTitle tag="h5">
                        Suitable for a short term/smaller engagement
                      </CardTitle>
                      <CardText>Minimum commitment of 40 hrs required</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6} className="mb-2">
                  <Card className="plan-card">
                    <CardBody>
                      <h2 className="font-weight-bold">AUD 2,500/month</h2>
                      <CardTitle tag="h5">
                        3-5 years Experienced developers
                      </CardTitle>
                      <CardText>
                        Minimum commitment of 160 hrs required
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6} className="mb-2">
                  <Card className="plan-card">
                    <CardBody>
                      <h2 className="font-weight-bold">AUD 150/day</h2>
                      <CardTitle tag="h5">
                        Suitable for a well defined scope to deliver
                      </CardTitle>
                      <CardText>Minimum commitment of 5 days required</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} lg={6} className="mb-2">
                  <Card className="plan-card">
                    <CardBody>
                      <h2 className="font-weight-bold">Fixed fee cost</h2>
                      <CardTitle tag="h5">
                        Get your free estimation for the requirement
                      </CardTitle>
                      <CardText>Fully customisable solutions</CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      <section className="facts-label p-0">
        <Container>
          <div className="py-1">
            <Row className="d-flex justify-content-center">
              <Col lg={5} md={12} className="d-sm-flex flex-row">
                <Col lg={6} md={5} sm={5} className="col-12 fact-label-text">
                  Transparent Pricing
                </Col>
                <Col lg={1} md={1} sm={1} className="col-12 fact-label-text">
                  •
                </Col>
                <Col lg={5} md={5} sm={5} className="col-12 fact-label-text">
                  Fully Signed NDA
                </Col>
              </Col>

              <Col
                lg={1}
                md={12}
                sm={12}
                className="fact-label-text d-sm-none d-lg-flex"
              >
                •
              </Col>

              <Col lg={6} md={12} className="d-sm-flex flex-row">
                <Col lg={5} md={5} sm={5} className="col-12 fact-label-text">
                  Code Security Assurance
                </Col>
                <Col lg={2} md={1} sm={1} className="col-12 fact-label-text">
                  •
                </Col>
                <Col lg={5} md={5} sm={5} className="col-12 fact-label-text">
                  Long-Term Relationship
                </Col>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="innovation-partner">
        <Container className="">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-1 align-center-xs">
                  Your Trusted Innovation Partner for Over a Decade!
                </div>
                <div className="sub-title align-center-xs">
                  We understand that as a business, you may face various
                  application development challenges such as limited skills,
                  budget constraints, or unclear technology direction.
                  That&apos;s where we come in - our comprehensive suite of
                  services includes UX/UI design, product design, full-stack
                  development, software testing, cloud engineering, and
                  long-term support to help your business thrive.
                </div>
              </div>
              <div className="tech-resources mt-3">
                <div className="title title-1 mb-2 align-center-xs">
                  Tech Stack
                </div>
                <p className="align-center-xs">
                  Quality Assurance and Project Coordination included.
                </p>
                <ul>
                  <li>Fullstack (NodeJS+React, React+.Net, PHP) </li>
                  <li>UX And Design (Figma) </li>
                  <li>Frontend (ReactJS, VueJS, PHP) </li>
                  <li>Backend (PHP, .Net, Node) </li>
                  <li>DevOps (AWS) </li>
                  <li>Mobile (iOS, Flutter) </li>
                  <li>QA Test Automation </li>
                  <li>Performance Testing </li>
                </ul>
              </div>
              <div className="mt-3">
                <h4 className="align-center-xs">
                  <b>How our resources can help</b>
                </h4>
                <ul>
                  <li>Clearing your backlog  </li>
                  <li>Feature additions to existing applications/systems </li>
                  <li>Short term capacity and capability management  </li>
                  <li>DB and Cloud migration  </li>
                </ul>
              </div>
              <div>
                <Col className="team-block mt-3 px-0 d-md-flex flex-row">
                  <Col lg={4} className="px-0">
                    <img className="content-img" src={Banner} alt="team-img" />
                  </Col>
                  <Col className="p-3" lg={8}>
                    <h4 className="align-center-xs">
                      Our team of
                      {" "}
                      <span className="font-weight-bold">
                        skilled professionals
                      </span>
                      {" "}
                      works hand-in-hand with you, ensuring
                      {" "}
                      <span className="font-weight-bold">
                        seamless collaboration
                      </span>
                      {" "}
                      and
                      {" "}
                      <span className="font-weight-bold">on-time delivery</span>
                      .
                    </h4>
                    <p className="mt-2 align-center-xs">
                      With our team operating in a time zone that closely
                      overlaps with yours, we ensure seamless communication and
                      timely updates throughout the entire development process,
                      guaranteeing efficiency and collaboration
                    </p>
                  </Col>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="p-0">
        <Container fluid className="p-0">
          <div className="exclusive-offer">
            <Container>
              <Row className="">
                <Col lg={9}>
                  <div className="">
                    <div className="title title-1 offer-title align-center-xs">
                      <span className="text-yellow">Don&apos;t miss out </span>
                      {" "}
                      on this exclusive offer
                    </div>
                    <div className="offer-text align-center-xs">
                      Secure your spot now and let iTelaSoft help you innovate,
                      develop, and grow like never before. Our team is ready and
                      eager to collaborate with you and make your vision a
                      reality.
                    </div>
                  </div>
                </Col>
                <Col
                  lg={3}
                  className="justify-content-lg-end align-items-center pt-3 pt-lg-0 align-center-btn"
                >
                  <div className="getting-touch-right">
                    <a
                      className="btn btn-primary btn-icon-button"
                      href="/contact/"
                    >
                      <span className="btn-text">Get Started Today!</span>
                      <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>

      <section className="client-testimonial-section">
        <Container>
          <div className="">
            <h3 className="title-1 align-center-xs">
              Clients across the world who’ve loved working with us
            </h3>
            <p className="subtitle align-center-xs">
              Over the years, we&apos;ve had the privilege of collaborating with
              a diverse array of visionary clients. From ambitious startups to
              industry-leading corporations, our journey is enriched by the
              trust and camaraderie we&apos;ve shared with each partner.
              Together, we&apos;ve embarked on thrilling ventures, tackled
              complex challenges, and celebrated remarkable achievements.
            </p>
          </div>
          <TabcmpSet tabData={tabData} tabNum={5} />
        </Container>
      </section>

      <section className="campaign-contact">
        <Row>
          <Col md={4} lg={4} xl={3}>
            <img src={Banner02} alt="team-img" />
          </Col>
          <Col
            md={7}
            lg={8}
            xl={9}
            className="title-section flex-column container"
          >
            <div className="title title-1 mobile align-center-xs">
              Let us know how to help
            </div>

            <div>
              <p className="align-center-xs">
                We love to hear what challenges you go through in your business
                and discuss how we can assist you.
              </p>
            </div>

            <div className="getting-touch-right">
              <Form
                onSubmit={onSubmitHandler}
                validate={validate}
                render={({ handleSubmit }) => (
                  <form
                    className="case-study-email-form mb-1 row"
                    onSubmit={handleSubmit}
                  >
                    <Field name="FirstName">
                      {({ input, meta }) => (
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-1">
                          <input
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...input}
                            type="text"
                            placeholder="Your name*"
                          />
                          {meta.error && meta.touched && (
                            <span className="error-val">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="email">
                      {({ input, meta }) => (
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-1">
                          <input
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...input}
                            type="email"
                            placeholder="Your e-mail address*"
                          />
                          {meta.error && meta.touched && (
                            <span className="error-val">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="Phone">
                      {({ input, meta }) => (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-1">
                          <input
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...input}
                            type="number"
                            placeholder="Contact number"
                            className="no-spinner"
                          />
                          {meta.error && meta.touched && (
                            <span className="error-val">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="message">
                      {({ input, meta }) => (
                        <div className="col-12 text-area">
                          <textarea
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...input}
                            placeholder="Your message"
                          />
                          {meta.error && meta.touched && (
                            <span className="error-val">{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div
                      className="my-1 col-12 w-100"
                      style={{ paddingBottom: "20px" }}
                    >
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                        onChange={onReCAPTCHAChange}
                      />

                      {recaptchaError && (
                        <span
                          style={{
                            fontSize: "13px",
                            color: "#dd0000",
                            fontWeight: 600,
                          }}
                        >
                          Required
                        </span>
                      )}
                    </div>
                    <div className="align-center-btn">
                      <Button
                        className={`btn btn-primary spinner-btn${
                          submitting ? " btn-disabled" : ""
                        }`}
                        variant="primary"
                        type="submit"
                        disabled={submitting}
                        onClick={handleSubmit}
                      >
                        <span className="btn-text mr-1">Inquire now</span>
                        <span className="btn-icon">
                          <SvgIconsSet.SideArrow />
                        </span>
                        {submitting && (
                          <div className="form-spinner-wrapper">
                            <span className="form-spinner loader" />
                          </div>
                        )}
                      </Button>
                    </div>
                  </form>
                )}
              />

              <div
                className={
                  formSuccess
                    ? "form-msg success show"
                    : "form-msg success d-none"
                }
              >
                <span>
                  Thanks for your message!
                  {" "}
                  <br />
                  {" "}
                  <span style={{ fontWeight: 400, padding: 0 }}>
                    We&apos;ll be in touch with you shortly.
                  </span>
                </span>
              </div>
              {!!submissionError && <div>{submissionError}</div>}
            </div>
          </Col>
        </Row>
      </section>

      <section className="social-contact ">
        <Container>
          <Row>
            <Col md={6} lg={6} xl={6}>
              <div className="icon-social-media">
                <a
                  href="https://www.facebook.com/iTelaSoft/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="facebook"
                >
                  <SvgIconsSet.GrayFaceBook />
                </a>
                <a
                  href="https://twitter.com/itelasoft"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="dsds"
                >
                  <SvgIconsSet.GrayTwitter />
                </a>
                <a
                  href="https://www.linkedin.com/company/itelasoft-pvt-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="linkedin"
                >
                  <SvgIconsSet.GrayLinkdin />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEBSHvKia8sRxDu8RbgWOqA"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="youtube"
                >
                  <SvgIconsSet.GrayYoutube />
                </a>
              </div>
            </Col>
            <Col md={3} lg={3} xl={3} className="contact-det">
              info@itelasoft.com
            </Col>
            <Col md={3} lg={3} xl={3} className="contact-det">
              +61 2 8896 4301
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default UX;
