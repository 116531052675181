import React from "react";
import DataGlider from '../images/home-page/logo/data-glider-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="video-out">
                    <div className="video-out-res">
                        <iframe
                        max-width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/Nqg_Sa2rpcs?si=ooQhNR004owePI7T`}
                        title="video-screen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="client-comment">
                    <div>
                        <p>“As the founder of a small software startup, I was looking for a partner to complement our team and help us with our product. That’s where iTelaSoft came in and shone. They were really fun to work with and collaborate with. The team also provided good structure to the project so we could achieve our milestone in a timely manner. We're really happy with the outcome and looking forward to continuing our partnership.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Mustafa Bensan</h6>
                            <p>Data Glider</p>
                        </div>
                        <div>
                            <img className="" alt="data-glider-logo" src={DataGlider} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
