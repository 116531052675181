import React from "react";
import Possibl from '../images/home-page/logo/possibl-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="video-out">
                    <div className="video-out-res">
                        <iframe
                        max-width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/2X-vyDsvwV4?si=2NWpZCASLtqw-OkZ`}
                        title="video-screen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="client-comment">
                    <div>
                        <p>“The iTelaSoft team are the team we depend on, when we need to implement complex features or functionality in a timely manner, with certainty that it will be done right, the first time, on time and budget. Being able to leverage off a knowledgeable team like the iTelaSoft team has enabled Possibl to grow and rapidly deploy features to our product.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Robbie Brender</h6>
                            <p>Possibl</p>
                        </div>
                        <div>
                            <img className="" alt="possibl-logo" src={Possibl} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
