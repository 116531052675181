import React from "react";
import Lilardia from '../images/home-page/logo/lilardia-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 p-0">
                <div className="video-out">
                    <div className="video-out-res">
                        <iframe
                        max-width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/-Bizxa5YtjM?si=_oqUjh6FLFJnolA3`}
                        title="video-screen"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="client-comment">
                    <div>
                        <p>“I’ve been working with iTelaSoft on a number of projects. Initially I engaged with iTelaSoft UX staff to design the product interface, and then with the technology staff to design the payment flows, the data standards, and the workflow of the overall platform. In all these aspects my experience with iTelaSoft has been a positive one, which has culminated in the successful launch of our product.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Eraj Ratnayake</h6>
                            <p>Lilardia Capital</p>
                        </div>
                        <div>
                            <img className="" alt="lilardia-capital-logo" src={Lilardia} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
